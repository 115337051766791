import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import usePagination from "hooks/usePagination";
import useRequest from "hooks/useRequest";

import i18n from "i18n";
import { PublicService } from "services/PublicService.jsx";
import { DeviceService } from "services/DeviceService.js";
import { TagService } from "services/TagService";
import { EventRepository } from "helpers/EventRepository";

import { steps } from "./data/steps";
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import StepProgress from "components/MainApp/atoms/Wizard/StepProgress";
import StepOne from "./Wizard/Step1";
import StepTwo from "./Wizard/Step2";
import StepThree from "./Wizard/Step3";

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddConfigTagSuccess from "../AddConfigTagSuccess";

import { isMobile } from "helpers/Mobile";
import { ParseDate, formatDateToYyyyMmDd } from "helpers/Parsers";
import * as XLSX from "xlsx";

import "./styles.scss";
import { be, ca } from "date-fns/locale";
import { Button, styled } from "@material-ui/core";
import { ProductService } from "services/ProductService";
import { blue } from '@mui/material/colors';
import UploadButton from "shared/components/buttons/upload-button";
import Environment from "./../../../../environment";
import BatchTagsErrorModal from "./BatchTagsErrorModal";

const initialTagInfo = {
  batch: "",
  due_date: formatDateToYyyyMmDd(new Date()),
  sscc: "",
  origin: "",
  destination: ""
};

const initialError = {
  success: true,
  message: "",
  errors: []
};

const colorImport = blue[500];

const AddTagView = props => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();
  const [activeStep, setActiveStep] = useState("0");
  const [sentSuccess, setSentSuccess] = useState(false);
  const [tag, settag] = useState(initialTagInfo);
  const [configTagId, setConfigTagId] = useState();
  const [configTag, setConfigTag] = useState();

  const [productResultList, setproductResultList] = useState([]);
  const [addedList, setaddedList] = useState([]);
  const [selectedToAddProductList, setselectedToAddProductList] = useState([]);
  const [
    list,
    currentPage,
    pageSize,
    pageList,
    pageQuantity,
    changePageSize,
    changePage,
    changeList
  ] = usePagination([], 0, 3);

  const [printer, setPrinter] = useState();
  const [printers, setPrinters] = useState([]);

  const [generatedTags, setGeneratedTags] = useState([]);
  const [itemsWithGeneratedTags, setItemsWithGeneratedTags] = useState([]);
  const [modalPersistOpen, setmodalPersistOpen] = useState(false);
  const [showModalErrorFile, setShowModalErrorFile] = useState(false);
  const [errorsFile, setErrorsFile] = useState([]);
  const [errorFile, setErrorFile] = useState(null);

  const headerOptions = [];

  useEffect(() => {
    DeviceService.devices({ device_type: "PRINTER" }).then(data => {
      data = data.map(device => {
        return { label: device.name, option: device.id };
      });
      setPrinters(data);
    });
  }, []);

  useEffect(() => {
    if (!configTag) {
      return;
    }

    beforeSubmit();
    if (configTag.type === "PRODUCT") {
      afterSubmit();
      /* PublicService.products_plain({ page_size: 999999, ordering: "-created_at" }).then(
         response => {
           response.results = response.results.map(product => {
             product.selected = false;
             product.units = 1;
             product.stock = 1;
             return product;
           });
           setproductResultList(response.results);
           afterSubmit();
         }
       ).catch(error => {
         afterSubmit();
       });*/
    } else {
      PublicService.containers({
        page_size: 999999,
        ordering: "-created_at",
        type: configTag.type
      }).then(response => {
        response.results = response.results.map(product => {
          product.selected = false;
          product.units = 1;
          product.stock = 1;
          return product;
        });
        setproductResultList(response.results);
        afterSubmit();
      }).catch(error => {
        afterSubmit();
      });
    }
  }, [configTag]);

  useEffect(() => {
    const id = props.match.params.key;
    setConfigTagId(id);

    TagService.configTag(id).then(configTag => {
      setConfigTag(configTag);
    });

    let persistedObj = JSON.parse(localStorage.getItem(id));
    if (persistedObj) {
      settag(persistedObj.tag);
      setaddedList(persistedObj.addedList);
      changeList(persistedObj.addedList, currentPage, pageSize);
      setPrinter(persistedObj.printer);
      setPrinters(persistedObj.printers);
      setGeneratedTags(persistedObj.generateTags);
      setItemsWithGeneratedTags(persistedObj.itemsWithGeneratedTags);
      setproductResultList(persistedObj.productResultList);
      setActiveStep(persistedObj.activeStep);
    }
  }, []);

  const filterProducts = (value) => {
    if (configTag.type === "PRODUCT") {
      if (value.length > 2) {
        return new Promise((resolve, reject) => {
          var filter = [];
          filter["search"] = value;
          beforeSubmit();

          PublicService.products_plain({ page_size: 999999, search: value }).then(
            response => {
              response.results = response.results.map(product => {
                product.selected = false;
                product.units = 1;
                product.stock = 1;
                return product;
              });
              setproductResultList(response.results);
              resolve(response.results);
              afterSubmit();
            }
          ).catch(error => {
            resolve([]);
            afterSubmit();
          });
        });
      }
    }
  }

  const handleGenerateTags = e => {
    e.preventDefault();
    if (
      generatedTags &&
      itemsWithGeneratedTags &&
      generatedTags.length > 0 &&
      itemsWithGeneratedTags.length > 0
    ) {
      setActiveStep("1");
      return;
    }

    beforeSubmit(true);
    let items = addedList.map(item => {
      let data = {
        sku: item.sku,
        quantity: item.units,
        variables: {
          name: item.name,
          batch: tag.batch,
          expiration: tag.due_date,
          date: ParseDate(new Date())
        }
      };
      if (configTag.type === "PRODUCT") {
        data["variables"]["description"] = item.description;
        data["variables"]["date"] = ParseDate(new Date());
      } else if (configTag.type === "BOX") {
        data["variables"]["sscc"] = tag.sscc;
        data["variables"]["origin"] = tag.origin;
        data["variables"]["destination"] = tag.destination;
      } else {
        data["variables"]["sscc"] = tag.sscc;
        data["variables"]["origin"] = tag.origin;
        data["variables"]["destination"] = tag.destination;
      }
      return data;
    });

    TagService.generateTags(configTagId, items)
      .then(tags => {
        afterSubmit();
        setGeneratedTags(tags);
        setItemsWithGeneratedTags(organizeTagsByItem(addedList, tags));
        setActiveStep("1");
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: "error"
            });
          } else {
            EventRepository.notificationSend({
              label: i18n.t(
                "Alguno de los productos seleccionados tiene problema."
              ),
              type: "error"
            });
          }
        });
      });
  };

  const organizeTagsByItem = (items, tags) => {
    return items.map(item => {
      item.tags = tags.filter(t => t.sku === item.sku);
      return item;
    });
  };

  const handleRemoveTags = () => {
    const data = generatedTags.map(t => {
      return { rfid: t.rfid };
    });

    beforeSubmit();

    TagService.removeTags(data)
      .then(_ => {
        afterSubmit();
        setGeneratedTags([]);
        setItemsWithGeneratedTags([]);
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", message => {
          EventRepository.notificationSend({
            label: message,
            type: "error"
          });
        });
      });
  };

  const handleFinishAndReceptionOrder = async e => {
    e.preventDefault();
    if (!validateStep2()) return;
    await handleFinish(e);
    history.push({
      pathname: "/admin/orders/reception/load",
      state: {generatedTags: generatedTags}});
  }

  const handleFinish = async (e) => {
    e.preventDefault();
    if (!validateStep2()) return;

    const data = {
      device: printer.option,
      rfids: generatedTags.map(t => t.rfid)
    };

    beforeSubmit();

    try {
      await TagService.printTags(data);
      afterSubmit();
      EventRepository.notificationSend({
        label: i18n.t("Se imprimieron los tags de manera exitosa."),
        type: "success"
      });
      localStorage.removeItem(configTagId);
      history.push(`/admin/config_tags`);
    } catch (error) {
      dealWithError(error, "generic.error", message => {
        EventRepository.notificationSend({
          label: message,
          type: "error"
        });
      });
    }
  };

  const handleClose = () => {
    handleRemoveTags();
    localStorage.removeItem(configTagId);
    setmodalPersistOpen(false);
    goToList();
  };

  const handleFinishStepTwo = () => {
    localStorage.removeItem(configTagId);
    setmodalPersistOpen(false);
    goToList();
  }

  const onlyCloseModal = () => {
    setmodalPersistOpen(false);
  }

  const handlePersist = () => {
    let data = {};
    data.tag = tag;
    data.addedList = addedList;
    data.printer = printer;
    data.printers = printers;
    data.generateTags = generatedTags;
    data.itemsWithGeneratedTags = itemsWithGeneratedTags;
    data.productResultList = productResultList;
    data.selectedToAddProductList = selectedToAddProductList;
    data.activeStep = activeStep;
    localStorage.setItem(configTagId, JSON.stringify(data));
    setmodalPersistOpen(false);
    goToList();
  };

  const persistenceGuard = () => {
    setmodalPersistOpen(true);
  };

  const persistTagPrintData = data => {
    localStorage.setItem(data.id, data);
  };

  const goToList = () => {
    history.push(`/admin/config_tags`);
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let tagAux = { ...tag };
    tagAux[name] = value;

    settag(tagAux);
  };

  const handleSelect = (selectedOption, type) => {
    setPrinter(selectedOption);
  };

  const handleSelectToAddProduct = product => {
    let selectedToAddProductListNew = [...selectedToAddProductList];
    let ind = selectedToAddProductListNew.findIndex(p => {
      return p.id === product;
    });
    if (ind !== -1) {
      selectedToAddProductListNew = selectedToAddProductListNew.filter(p => {
        return p.id !== product;
      });
    } else {
      let newProduct = productResultList.find(p => {
        return p.id === product;
      });
      newProduct.selected = true;
      selectedToAddProductListNew.push(newProduct);
    }

    setselectedToAddProductList(selectedToAddProductListNew);
  };

  const handleSelectedProductQuantityChange = (productId, number) => {
    let productResultListNew = [...productResultList];
    let productNew = productResultListNew.find(p => p.id === productId);

    productNew.units = number;
    setproductResultList(productResultListNew);
  };

  const handleAdd = () => {
    let addedListNew = [...addedList];
    selectedToAddProductList.forEach(product => {
      let addedInd = addedList.findIndex(p => {
        return p.id === product.id;
      });
      if (addedInd === -1) {
        addedListNew.push({ ...product });
      } else {
        let productSelected = {
          ...productResultList.find(p => p.id === product.id)
        };
        let productAddedIndex = addedListNew.findIndex(
          p => p.id === product.id
        );
        addedListNew[productAddedIndex].units =
          Number(addedListNew[productAddedIndex].units) +
          Number(productSelected.units);
      }

      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize);
    });

    let productResultListNew = productResultList.map(p => {
      p.units = 1;
      return p;
    });

    setproductResultList(productResultListNew);
    setselectedToAddProductList([]);
  };

  const handleAddProduct = (productId, number) => {
    number = number === 0 ? 1 : number;

    let addedListNew = [...addedList];
    let productAddedIndex = addedListNew.findIndex(p => p.id === productId);
    addedListNew[productAddedIndex].units = Number(number);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const handleDeleteAddedProduct = productId => {
    let addedListNew = [...addedList];

    addedListNew = addedListNew.filter(p => p.id !== productId);
    setaddedList(addedListNew);
    changeList(addedListNew, currentPage, pageSize);
  };

  const validateStep0 = () => {
    if (tag) {
      return (
        tag.batch &&
        tag.batch !== "" &&
        tag.due_date &&
        tag.due_date !== "" &&
        addedList.length > 0
      );
    } else {
      return false;
    }
  };

  const validateStep1 = () => {
    if (tag) {
      return tag.width !== "" && tag.height !== "";
    } else {
      return false;
    }
  };

  const validateStep2 = () => {
    if (tag) {
      return printer;
    } else {
      return false;
    }
  };

  const goToStep = step => {
    setActiveStep(step);
  };

  const resetTags = () => {
    handleRemoveTags();
    setActiveStep("0");
  };

  const validateFile = file => {
    // si el archivo no es xlsx o ods lanzamos error
    if (!file.name.match(/\.(xlsx|ods)$/)) {
      EventRepository.notificationSend({
        label: i18n.t(
          "El archivo de importación debe ser en formato excel / ods"
        ),
        type: "error"
      });
      return false;
    }
    return true;
  }

  const validateHeadersFile = (sheet) => {
    if (sheet["A1"].v.toLowerCase() !== "codigo" || sheet["B1"].v.toLowerCase() !== "cantidad") {
      EventRepository.notificationSend({
        label: i18n.t( "El archivo de importación debe tener las columnas CODIGO y CANTIDAD"),
        type: "error"
      });
      return false;
    }
    return true;
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!validateFile(file)) return false;

    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      if (!validateHeadersFile(sheet)) return false;

      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      insertProducts(sheetData);
    };

    reader.readAsBinaryString(file);
  };


  const insertProducts = (sheetData) => {
    sheetData.splice(0, 1); // eliminamos la cabecera
    let first_row_data = 2;
    const cleanProducts = [];

    // si la celda incluye alfamumérico, eliminamos la fila
    if (sheetData[0][0]?.toString().toLowerCase().includes("alfanumérico")) {
      sheetData.splice(0, 1);
      first_row_data = 3;
    }

    sheetData.forEach((row) => {
      if (row && row.length > 1 && row[0] != "") {
        let product = {
          codigo: row[0]?.toString(),
          count: row[1]
        }
        const productAdded = cleanProducts.find(p => p.codigo === product.codigo);
        if (productAdded) {
          productAdded.count = productAdded.count + product.count;
        } else {
          cleanProducts.push(product)
        }
      }
    });

    loadProductsBySKU(cleanProducts, first_row_data);

  }

  const loadProductsBySKU = async (cleanProducts, first_row_data) => {
    setErrorsFile([]);
    let addedListNew = [...addedList];
    let errors = [];
    let row_data = first_row_data;
    for (const p of cleanProducts) {
      const row_number_aux = row_data;
      row_data++;
      beforeSubmit();
      try {
        // si el codigo está vacío se lanza error
        p.codigo = p?.codigo?.trim();
        if(!p.codigo){
          throw new Error(`Falta el código`);
        }

        if (isNaN(Number(p.count))) {
          throw new Error(`Se requiere un número válido`);
        }

        let result = await ProductService.itemBySKU(p.codigo, configTag.type);
        if (!result.results || result.results.length === 0) {
          throw new Error(`No existe el SKU (CODIGO): ${p.codigo}`);
        }

        let product = result.results[0];
        product["selected"] = true;
        product["units"] = Number(p.count);
        product["stock"] = Number(p.count);

        let productAddedIndex = addedListNew.findIndex(item => item.id === product.id);

        if (productAddedIndex > -1) {
          addedListNew[productAddedIndex].units += Number(p.count);
          addedListNew[productAddedIndex].stock += Number(p.count);
        } else {
          addedListNew.push(product);
        }
      } catch (error) {
        errors.push({ fila: row_number_aux, error: error.message });
      }
      afterSubmit();
    }

    if (errors.length > 0) {
      // Generar el fichero XLSX con los errores
      const ws = XLSX.utils.json_to_sheet(errors);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Errores");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const errorFileUrl = URL.createObjectURL(blob);
      setErrorFile(errorFileUrl);
      setShowModalErrorFile(true);
    } else {
      setErrorsFile([]);
      setaddedList(addedListNew);
      changeList(addedListNew, currentPage, pageSize); // Asegúrate de que esto también esté basado en el estado actualizado
    }
  };

  const downloadErrorFile = () => {
    const link = document.createElement('a');
    link.href = errorFile;
    link.download = 'errores.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const headerTitle = sentSuccess ? "Plantilla creada" : "Generación de Tags";

  const downloadTemplate = (e) => {
    e.preventDefault();
    window.open(Environment.statics + "files/impresiontags.xlsx", "_blank");
  };

  const step0 = (
    <div>
      <div className="position-upload-button">
        <UploadButton  handleFileUpload={handleFileUpload}></UploadButton>
        <div>
          <Button
            component="label"
            className="button-info"
            onClick={downloadTemplate}
          >
          <i className="icon-iconos_documento"></i>
          Info
          </Button>
        </div>
      </div>
      <StepOne
        handleChange={handleChange}
        resultList={productResultList}
        searchValue={""}
        products={isMobile ? addedList : pageList}
        totalProducts={addedList.length}
        handleSelectToAddProduct={handleSelectToAddProduct}
        handleSelectedProductQuantityChange={handleSelectedProductQuantityChange}
        handleAdd={handleAdd}
        handleAddProduct={handleAddProduct}
        selectedProducts={selectedToAddProductList.map(p => p.id)}
        handleDeleteAddedProduct={handleDeleteAddedProduct}
        changePage={changePage}
        pagination={{
          pageQuantity: pageQuantity,
          currentPage: currentPage
        }}
        handleContinue={handleGenerateTags}
        enableContinue={validateStep0()}
        type={configTag?.type}
        tag={tag}
        error={errors}
        handleClose={handleClose}
        handlePersist={handlePersist}
        onlyCloseModal={onlyCloseModal}
        modalPersistOpen={modalPersistOpen}
        filter={filterProducts}
      />

      {/* Modal para mostrar los posible errores del fichero de importación  */}
      <BatchTagsErrorModal
        open={showModalErrorFile}
        handleClose={() => setShowModalErrorFile(false)}
        handleDownload={downloadErrorFile}
      />

    </div>

  );

  const step1 = (
    <StepTwo
      handleContinue={() => setActiveStep("2")}
      handleEdit={() => resetTags()}
      enableContinue={validateStep1()}
      tag={tag}
      error={errors}
      addedProducts={itemsWithGeneratedTags}
      handleClose={handleClose}
      handlePersist={handlePersist}
      modalPersistOpen={modalPersistOpen}
      onlyCloseModal={onlyCloseModal}
      handleFinishStepTwo={handleFinishStepTwo}
    ></StepTwo>
  );

  const step2 = (
    <StepThree
      handleFinish={handleFinish}
      goBackFunc={() => goToStep("1")}
      printer={printer}
      handleSelect={handleSelect}
      error={errors}
      printers={printers}
      enableContinue={validateStep2()}
      handleClose={handleClose}
      handlePersist={handlePersist}
      modalPersistOpen={modalPersistOpen}
      onlyCloseModal={onlyCloseModal}
      handleFinishAndReceptionOrder={handleFinishAndReceptionOrder}
    ></StepThree>
  );

  // const stepList = [step0, step1, step2];
  const stepList = {
    "0": step0,
    "1": step1,
    "2": step2
  };

  const addSuccess = (
    <div className={`add-tag-success`}>
      <AddConfigTagSuccess
        id={tag && tag.id}
        name={tag && tag.name}
        boldButtonFunc={goToList}
      />
    </div>
  );

  const content = (
    <div className={`add-tag-content`}>
      {!sentSuccess && (
        <div className={`add-tag-wrapper`}>
          <StepProgress steps={steps} activeStep={activeStep} />
          <div className={`add-tag-step-wrapper`}>{stepList[activeStep]}</div>
        </div>
      )}
    </div>
  );

  return (
    <div className="add-tag-container">
      <AdminLayout
        headerTitle={i18n.t(headerTitle)}
        headerOptions={headerOptions}
        content={sentSuccess ? addSuccess : content}
        navHidden={true}
        goBackFunc={persistenceGuard}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default AddTagView;
